<template>
  <div class="max-w-[1920px] relative mx-auto">
    <Header/>
    <!--    Desktop-->
    <div class="mx-auto max-w-[95%] sm:max-w-[90%] md:max-w-[1500px]" v-if="banner_top && !mobile">
      <swiper
          class="md:mt-[76px] mt-0 mb-2"
          :modules="[Navigation, Scrollbar, A11y, Autoplay]"
          :slides-per-view="1"
          :space-between="50"
          :allowTouchMove="false"
          :autoplay="{delay: 10000}"
          :loop="true"
      >
        <template v-for="(item,index) in banners">
          <swiper-slide>
            <div class="md:h-[100px] max-w-[1500px] mx-auto px-2">
              <NuxtLink :href="item.link" target="_blank">
                <template v-if="index<1">
                  <nuxt-img
                      preload
                      width="1500"
                      height="100"
                      format="webp"
                      :alt="item.title"
                      :src="siteURL + item.src"
                      class="w-full h-full object-contain"/>
                </template>
                <template v-else>
                  <nuxt-img
                      width="1500"
                      height="100"
                      format="webp"
                      :alt="item.title"
                      :src="siteURL + item.src"
                      class="w-full h-full object-contain"/>
                </template>
              </NuxtLink>
            </div>
          </swiper-slide>
        </template>
      </swiper>
    </div>
    <!--    Mobile-->
    <div class="mx-auto max-w-[95%] sm:max-w-[90%] md:max-w-[1500px]" v-if="banner_top && mobile">
      <swiper
          class="md:mt-[76px] mt-0 mb-2 md:mb-10"
          :modules="[Navigation, Scrollbar, A11y, Autoplay]"
          :slides-per-view="1"
          :space-between="50"
          :allowTouchMove="false"
          :autoplay="{delay: 10000}"
          :loop="true"
      >
        <template v-for="(item,index) in banners">
          <swiper-slide v-if="item.src_mobile">
            <div class="md:h-[120px] max-w-[1140px] mx-auto">
              <NuxtLink :href="item.link" target="_blank">
                <template v-if="index<1">
                  <nuxt-img
                      preload
                      width="640"
                      height="120"
                      format="webp"
                      :alt="item.title"
                      :src="siteURL + item.src_mobile"
                      class="w-full h-full object-contain"/>
                </template>
                <template v-else>
                  <nuxt-img
                      width="640"
                      height="120"
                      format="webp"
                      :alt="item.title"
                      :src="siteURL + item.src_mobile"
                      class="w-full h-full object-contain"/>
                </template>
              </NuxtLink>
            </div>
          </swiper-slide>
        </template>
      </swiper>
    </div>
    <slot>

    </slot>
<!--    Desktop-->
    <div class="mx-auto max-w-[95%] sm:max-w-[90%] md:max-w-[1500px]" v-if="banner_bottom && !mobile">
      <swiper
          class="md:mt-[76px] mt-0 mb-2"
          :modules="[Navigation, Scrollbar, A11y, Autoplay]"
          :slides-per-view="1"
          :space-between="50"
          :allowTouchMove="false"
          :autoplay="{delay: 10000}"
          :loop="true"
      >
        <template v-for="(item,index) in banners">
          <swiper-slide>
            <div class="md:h-[100px] max-w-[1500px] mx-auto px-2">
              <NuxtLink :href="item.link" target="_blank">
                <template v-if="index<1">
                  <nuxt-img
                      preload
                      width="1500"
                      height="100"
                      format="webp"
                      :alt="item.title"
                      :src="siteURL + item.src"
                      class="w-full h-full object-contain"/>
                </template>
                <template v-else>
                  <nuxt-img
                      width="1500"
                      height="100"
                      format="webp"
                      :alt="item.title"
                      :src="siteURL + item.src"
                      class="w-full h-full object-contain"/>
                </template>
              </NuxtLink>
            </div>
          </swiper-slide>
        </template>
      </swiper>
    </div>
<!--    Mobile-->
    <div class="mx-auto max-w-[95%] sm:max-w-[90%] md:max-w-[1500px]" v-if="banner_bottom && mobile">
      <swiper
          class="md:mt-[76px] mt-0 mb-2 md:mb-10"
          :modules="[Navigation, Scrollbar, A11y, Autoplay]"
          :slides-per-view="1"
          :space-between="50"
          :allowTouchMove="false"
          :autoplay="{delay: 10000}"
          :loop="true"
      >
        <template v-for="(item,index) in banners">
          <swiper-slide v-if="item.src_mobile">
            <div class="md:h-[120px] max-w-[1140px] mx-auto">
              <NuxtLink :href="item.link" target="_blank">
                <template v-if="index<1">
                  <nuxt-img
                      preload
                      width="640"
                      height="120"
                      format="webp"
                      :alt="item.title"
                      :src="siteURL + item.src_mobile"
                      class="w-full h-full object-contain"/>
                </template>
                <template v-else>
                  <nuxt-img
                      width="640"
                      height="120"
                      format="webp"
                      :alt="item.title"
                      :src="siteURL + item.src_mobile"
                      class="w-full h-full object-contain"/>
                </template>
              </NuxtLink>
            </div>
          </swiper-slide>
        </template>
      </swiper>
    </div>
    <Footer/>
  </div>
</template>
<script setup>
import {computed, ref} from 'vue';
import {http} from "~/utils/http";
import {Swiper, SwiperSlide} from "swiper/vue";
import {Navigation, Scrollbar, A11y, Autoplay,} from "swiper";
import {useHead, useRoute} from "nuxt/app";
import {Page} from "~/composables/nhTypes";
import {useSiteTitle, useSiteURL} from "../composables/state";

const banners = ref([]);
const page = ref(new Page());
const banner_top = ref(false);
const banner_bottom = ref(false);
const route = useRoute();
const path = computed(() => route.path)
const siteURL = useSiteURL();
const siteTitle = useSiteTitle();
const mobile = computed(() => {
  if (process.client) {
    return window.innerWidth < 770;
  } else {
    return  false;
  }
})
useHead({
  title: computed(()=>  siteTitle.value),
  meta: [
    {name: 'description', content: computed(()=> 'Since its beginnings in 1865, National Horseman has a long history of setting the standard for the Saddlebred industry and the show horse world.')},
    {name: 'keywords', content: computed(()=>  siteTitle.value)},
  ],
})

const getPages = async () => {
  try {
    await http
        .get("/api/pages/get-page", {
          params: {
            permalink: '/',
          }
        })
        .then((response) => {
          page.value = response.data.page;
          if (response.data.page.id) {
            getBanners(page.value.id);
          }
          if (page.value.banner_position === 2) {
            banner_bottom.value = true;
          } else if (page.value.banner_position === 3) {
            banner_top.value = true;
          } else if (page.value.banner_position === 4) {
            banner_bottom.value = true;
            banner_top.value = true;
          }
        });
  } catch (e) {
  }
}

const getBanners = async (id) => {
  try {
    await http
        .get("/api/banners/get-banners-front", {
          params: {
            page_id: id
          }
        })
        .then((response) => {
          response.data.banners.forEach((banner) => {
            banners.value.push({
              pages: banner.pages,
              title: banner.title,
              link: banner.link,
              src: '/storage/images/banners/' + banner.path + '/' + banner.image,
              src_mobile:  banner.image_mobile? '/storage/images/banners/' + banner.path + '/' + banner.image_mobile:null,
            })
          })
        });
  } catch (e) {
  }
}

await getPages();
</script>
